import React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo";
import Social from '../components/social';

export default function FederalTaxForm({pageContext}) {
  const formName = pageContext.formName;
  const {Description, IRS_Link, Special_Notes} = pageContext.form;
  const forms = pageContext.forms;
  const currentDate = new Date().getFullYear();


  return (
    <Layout>
      <SEO title={"Federal Tax Form: " + formName} description={"Federal Tax Form " + formName}/>
      <div>

        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb">
                <div className="breadcrumb">
                  <div className="breadcrumb-item"><Link to="/">Home</Link></div>
                  <div className="breadcrumb-item"><Link to="/federal-tax-forms">Federal Tax Forms</Link></div>
                  <div className="breadcrumb-item active" aria-current="page">{formName}</div>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-sm-12 col-md-8">
              <p className="hero-meta">Last updated: {currentDate}</p>
              <h1 className="hero-heading">Federal Tax Form {formName}</h1>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-sm-12 col-md-9">
              <Social />
              <hr/>
              <h2>Form {formName}</h2>
              <p>{Description}</p>

              {
                (Special_Notes) => {
                return (
                  Special_Notes && <p>{Special_Notes}</p>
                );
              }
              }

              <div id="tax-form">
                <embed src={IRS_Link} type="application/pdf" width="100%" height="100%"/>
              </div>
              <br/>
              <p>Back to <Link to="/federal-tax-forms">IRS Federal Tax Forms</Link></p>

            </div>
            <div className="col-sm-12 col-md-3">
              <h2>IRS Tax Forms</h2>
              <ul>
                {forms.map(form => {
                  return(
                    <li>
                      <Link to={"/federal-tax-form/" + form.Form_Name.split(/\s/)[1]}>{form.Form_Name}</Link>
                    </li>
                  )
                })}
              </ul>
            </div>
            {/* End main column */}
          </div>
          {/* End row */}
        </div>
        {/* End container */}
      </div>
    </Layout>
  );
}

